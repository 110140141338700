import React, { useEffect, useReducer, useContext } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MUISwitch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from 'tcweb-material-components/core/box'
import Icon from 'tcweb-material-components/core/icon'
import Section from 'tcweb-material-components/core/section'
import Image from 'tcweb-material-components/core/image'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import jackbot from '../assets/images/jackbot.png'
import scratchMask from '../assets/images/scratch-container.svg'
import PushNotificationContext from '../components/context/pushNotificationContext'
import LayoutContent from '../components/layout/layoutContent'

const fullWidth = (breakpoints, gutters) =>
    ['xs', 'sm'].reduce(
        (styleObj, breakpoint) => ({
            ...styleObj,
            [breakpoints.only(breakpoint)]: {
                marginLeft: `-${gutters.page[breakpoint]}`,
                width: `calc(100% + (${gutters.page[breakpoint]} * 2))`,
                maxWidth: `calc(100% + (${gutters.page[breakpoint]} * 2))`
            }
        }),
        {}
    )

const Switch = withStyles(({ palette }) => ({
    root: {
        overflow: 'visible'
    },
    switchBase: {
        overflow: 'hidden',
        color: palette.text.primary,
    },
    track: {
        opacity: 1,
        height: '18px',
        width: '40px',
        backgroundColor: '#757575',
        border: '1px solid #000000'
    },
    thumb: {
        position: 'relative',
        zIndex: 2,
        width: '24px',
        height: '24px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #000000',
        filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))'
    },
    disabled: {
        '& + $track': {
            opacity: '1 !important',
            backgroundColor: '#C5C5C6 !important',
            borderColor: '#979797'
        },
        '& $thumb': {
            color: '#979797',
            backgroundColor: 'currentcolor',
            borderColor: 'transparent',
        }
    },
    checked: {
        '& + $track': {
            opacity: '1 !important',
            borderColor: '#E2251C',
            backgroundColor: '#7E0707 !important'
        },
        '& $thumb': {
            color: '#E43027',
            backgroundColor: 'currentcolor',
            borderColor: 'transparent'
        }
    }
}), { withTheme: true })(MUISwitch)

const useStyles = makeStyles(({ breakpoints, gutters, palette, spacing, accessibility: { highContrast } }) => ({
    layout: {
        [breakpoints.up('lg')]: {
            justifyContent: 'center'
        }
    },
    root: {
        width: '100%',
        [breakpoints.up('md')]: {
            alignSelf: 'center',
            paddingTop: '100px',
            paddingBottom: '100px'
        },
        [breakpoints.up('lg')]: {
            justifySelf: 'center',
            width: 'calc(100% / 2)'
        },
        [highContrast.mediaQuery('active')]: {
            border: '1px solid windowText'
        }
    },
    content: {
        ...fullWidth(breakpoints, gutters),
        maskBoxImage: `url(${scratchMask})`,
        padding: '1em',
        [breakpoints.down('sm')]: {
            marginTop: '-5px'
        },
        [breakpoints.up('md')]: {
            padding: '50px'
        }
    },
    fieldGroup: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        padding: '20px',
        borderTop: `1px solid ${palette.border.primary}`,
        borderBottom: `1px solid ${palette.border.primary}`,
        [breakpoints.down('sm')]: {
            ...fullWidth(breakpoints, gutters)
        },
        [breakpoints.up('md')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    characterContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [breakpoints.down('sm')]: {
            ...fullWidth(breakpoints, gutters),
            padding: '20px 0'
        },
        [breakpoints.only('md')]: {
            display: 'none'
        },
        [breakpoints.up('lg')]: {
            position: 'absolute',
            left: 50,
            top: 0,
            bottom: 0,
            maxWidth: 'calc(100vw / 4)'
        }
    },
    characterImage: {
        borderStyle: 'none',
        width: '100%',
        [breakpoints.down('sm')]: {
            width: '50%',
            margin: 'auto'
        }
    },
    defaultAlert: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridGap: spacing(1),
        padding: '20px'
    },
    errorIcon: { color: '#BF0A0A', marginTop: '2px' },
    errorAlert: {
        color: palette.common.black,
        backgroundColor: '#FFDDDD'
    },
    infoIcon: { color: palette.text.primary, marginTop: '2px' },
    infoAlert: {
        color: palette.text.primary,
        backgroundColor: '#222222'
    }
}))

const reducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, ...action.payload }
        default:
            return state
    }
}

const Notifications = (props) => {
    const _classes = useStyles()
    const { t } = useTranslation()
    const { permissionResult, askPermission, canReceivePushNotification, subscribe, unsubscribe, subscription } = useContext(PushNotificationContext)
    const [state, dispatch] = useReducer(reducer, { notifications: null, loading: true, hasError: false })
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    const stopLoading = () => dispatch({ type: 'set', payload: { loading: false } })
    const setError = (hasError) => dispatch({ type: 'set', payload: { hasError } })

    const toggleSwitch = (name) => (e) => {
        if (state.loading) return
        dispatch({ type: 'set', payload: { loading: true, hasError: false } })

        !!window &&
            !!window.appInsights &&
            window.appInsights.trackEvent({
                name: 'Subscription toggle',
                properties: {
                    type: name,
                    action: state[name] ? 'subscribing' : 'unsubscribing'
                }
            })

        if (!state[name]) {
            if (permissionResult === 'granted') {
                subscribe()
                    .then(() => dispatch({ type: 'set', payload: { [name]: true } }))
                    .catch(() => setError(true))
                    .finally(stopLoading)
            } else {
                askPermission().then((permissionResult) => {
                    if (permissionResult === 'granted') {
                        subscribe()
                            .then(() => dispatch({ type: 'set', payload: { [name]: true } }))
                            .catch(() => setError(true))
                            .finally(stopLoading)
                    } else if (permissionResult === 'denied') {
                        !!window &&
                            !!window.appInsights &&
                            window.appInsights.trackEvent({
                                name: 'Notification Request',
                                properties: {
                                    result: 'denied'
                                }
                            })
                    }
                })
            }
        } else {
            unsubscribe()
                .then(() => dispatch({ type: 'set', payload: { [name]: false } }))
                .catch(() => setError(true))
                .finally(stopLoading)
        }
    }

    useEffect(() => {
        dispatch({ type: 'set', payload: { notifications: !!subscription, loading: false } })
    }, [subscription])

    if (canReceivePushNotification === null) return null

    if (!canReceivePushNotification) {
        return (
            <LayoutContent {...props} setBackgroundImage>
                <Box paddingTop='4em'>
                    <Typography variant='h1' className='h2'>
                        {t("Your browser doesn't support Push Notifications.")}
                    </Typography>
                </Box>
            </LayoutContent>
        )
    }

    return (
        <LayoutContent {...props} mainClass={_classes.layout}>
            <Helmet title={`${props.data.siteData.siteMetadata.title} | ${t('Notifications')}`} />

            <Box className={_classes.characterContainer}>
                <Image src={jackbot} alt={t('Jackbot Default Skin')} className={_classes.characterImage} />
            </Box>
            <Box className={_classes.root}>
                <Section color='primary' itemMargins={{ mobile: '20px', tablet: '20px' }} className={_classes.content}>
                    <Typography variant='h1' className='h2'>
                        {t('Notification Settings')}
                    </Typography>

                    {state.hasError && (
                        <Box className={classnames(_classes.defaultAlert, _classes.errorAlert)}>
                            <Icon iconBase='gears' iconPrefix='tcr' iconName={'error-circle'} size='lg' className={_classes.errorIcon} />
                            <Typography aria-live='assertive'>
                                {!!subscription
                                    ? t('An error occurred while unsubscribing from push notifications.')
                                    : t('An error occurred while subscribing to push notifications.')}
                            </Typography>
                        </Box>
                    )}

                    <Section itemMargins={{ mobile: '10px', tablet: '20px', desktop: '20px' }} className={_classes.fieldsContainer}>
                        <Box className={_classes.fieldGroup}>
                            <Typography variant='h2' className='h4' style={{ order: 1 }}>
                                {t('Notifications')}
                            </Typography>
                            <Typography style={{ order: 3 }}>{t('Opt-in to receive push notifications on this device.')}</Typography>
                            <FormControlLabel
                                style={{ marginRight: 0, order: 2 }}
                                control={
                                    <Switch
                                        color='primary'
                                        aria-disabled={permissionResult === 'denied' || state.loading}
                                        checked={state.notifications}
                                        onChange={toggleSwitch('notifications')}
                                        disableRipple={false}
                                        inputProps={{
                                            role: 'switch',
                                            'aria-label': 'Notifications',
                                            'aria-disabled': permissionResult === 'denied',
                                            'aria-busy': state.loading,
                                            'aria-checked': toggleState
                                        }}
                                    />
                                }
                                label={permissionResult === 'denied' ? t('DISABLED') : state.notifications ? t('ON') : t('OFF')}
                            />
                        </Box>
                    </Section>
                    {permissionResult === 'denied' && (
                        <Box className={classnames(_classes.defaultAlert, _classes.infoAlert)}>
                            <Icon iconBase='gears' iconPrefix='tcr' iconName={'info-circle'} size='lg' className={_classes.infoIcon} />
                            <Typography>{t('Your notifications are blocked. Go to your browser settings panel to unblock.')}</Typography>
                        </Box>
                    )}
                </Section>
            </Box>
        </LayoutContent>
    )
}

export const pageQuery = graphql`
    query NotificationsQuery {
        siteData: site {
            siteMetadata {
                title
            }
        }
    }
`

export default Notifications
